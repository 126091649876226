import axios from "axios"
import store from "@/store"
import TokenService from "./TokenService"

const app_type = localStorage.getItem('app_type') ? JSON.parse(localStorage.getItem('app_type')) : null

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

instance.defaults.timeout = 60000
instance.defaults.withCredentials = true

if(!app_type) {
    instance.defaults.xsrfHeaderName = 'X-CSRFToken'
    instance.defaults.xsrfCookieName = 'csrftoken'
}

instance.interceptors.request.use(
    async config => {
        if(app_type) {
            const token = await TokenService.getAccessToken()
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
        }
        
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

if(app_type) {
    instance.interceptors.response.use(
        async res => {
            await TokenService.checkAllToken()
            return res
        },
        async err => {
            console.log(err, 'err')
            const originalConfig = err.config
            if ((err.response.status === 401 || err.response.status === 403 || err.response.status === 405) && !originalConfig._retry) {
                originalConfig._retry = true
                try{
                    await TokenService.getAccessToken(err.response.data)
                    return instance(originalConfig)
                } catch (err) {
                    return Promise.reject(err)
                }
            }
        }
    )
} else {
    instance.interceptors.response.use(response => response,
        async ({ message, response, config }) => {
            if(response.status === 503) {
                const originalRequest = config
                originalRequest._retry = true
                return instance(originalRequest)
            } else {
                if (response?.status === 401) {
                    await store.dispatch('user/localUserLogout')
                    location.reload()
                }
                throw response?.data
            }
        })
}

export default instance
