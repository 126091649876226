<template>
    <div id="app">
        <router-view/>
        <template v-if="!appType">
            <template v-if="checkDrawerShow">
                <template v-if="config && config.injectInit && config.injectInit.length">
                    <initSwitch
                        v-for="(folder, index) in injectInit"
                        :key="index"
                        :folder="folder" />
                </template>
                <MyDeliveryPoints />
                <component :is="setPassword" />
            </template>
            <NetworkStatus v-if="!online" />
        </template>
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script>
import 'moment/locale/ru'
import initSwitch from '@/components/initSwitch'
import MyDeliveryPoints from '@apps/LogisticMonitor/components/MyDeliveryPoints.vue'
import { mapState } from 'vuex'
import launchQueue from '@/mixins/launchQueue'
export default {
    mixins: [launchQueue],
    sockets: {
        connect() {
            console.log('%c Socket connected 🤝', 'color: #1d65c0')
        },
        chat_online_user(data) {
            this.$store.commit('user/SET_ONLINE_USER', data)
        },
        chat_offline_user(data) {
            this.$store.commit('user/SET_OFFLINE_USER', data)
        }
    },
    metaInfo() {
        return {
            title: this.siteName
        }
    },
    components: {
        initSwitch,
        MyDeliveryPoints,
        NetworkStatus: () => import('@/components/NetworkStatus')
    },
    computed: {
        ...mapState({
            config: state => state.config.config,
            online: state => state.online,
            user: state => state.user.user,
            appType: state => state.appType
        }),
        checkDrawerShow() {
            if(this.$route.name !== 'page_404' 
            && this.$route.name !== 'login' 
            && this.$route.name !== 'forgotPassword' 
            && this.$route.name !== 'resetPassword' 
            && this.$route.name !== 'joinUser'
            && this.$route.name !== 'forgotPasswordEmail' 
            && this.$route.name !== 'forgotPasswordPhone'
            && this.$route.name !== 'registration') {
                return true
            } else
                return false
        },
        siteName() {
            if(this.config?.site_setting?.site_name)
                return this.config.site_setting.site_name
            else
                return 'Gos24.КОННЕКТ'
        },
        setPassword() {
            if(this.user?.password_generated)
                return () => import('@/components/PasswordSet.vue')
            else
                return null
        },
        injectInit() {
            if(this.appType && this.config?.injectInit?.length) {
                let inject = []

                this.config.injectInit.forEach(item => {
                    if(!item.includes('vue2TaskComponent') && !item.includes('Notifications') && !item.includes('vue2GroupsAndProjectsComponent') && !item.includes('vue2MeetingComponent') && !item.includes('vue2ChatComponent'))
                        inject.push(item)
                })

                return inject
            } else {
                return this.config?.injectInit?.length ? this.config.injectInit : []
            }
        }
    },
    created() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.$store.commit('SET_PWA_POPUP', e)
        })

        // Create css --vh variable for calc viewport considering the browser interface
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });


        /*if (window.matchMedia('(display-mode: standalone)').matches) {
            console.log('pwa')
        } else {
            console.log('not pwa')
        }*/
    },
    mounted() {
        this.$moment.locale('ru')
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        this.$store.commit('UPDATE_WINDOW_HEIGHT', window.innerHeight)

        document.addEventListener("visibilitychange", () => {
            if (document.hidden)
                this.$store.commit('TOGGLE_VISIBILITY_STATE', false)
            else
                this.$store.commit('TOGGLE_VISIBILITY_STATE', true)
        })

        window.addEventListener('online', () => this.$store.commit('TOGGLE_ONLINE', true))
        window.addEventListener('offline', () => this.$store.commit('TOGGLE_ONLINE', false))

        let resizeId;
        window.addEventListener('resize', () => {
            clearTimeout(resizeId)
            resizeId = setTimeout(() => {
                this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
                this.$store.commit('UPDATE_WINDOW_HEIGHT', window.innerHeight)
            }, 150)
        })
    }
}
</script>