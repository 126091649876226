import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import messages from '@/lang/ru'
import axios from './axios'
import axiosPush from './axiosPush'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages: { ru: {} }
})

export const langList = ['ru'] // , 'en', 'kk'

export const loadedLanguages = []

function setI18nLanguage (lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    axiosPush.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    localStorage.setItem('lang', lang)
    return lang
}

export async function asyncInitLang() {
    const lang = localStorage.getItem('lang')
    
    await loadLanguageAsync(lang || 'ru')
}

export function loadLanguageAsync(lang) {
    if (i18n.locale === lang && lang !== 'ru')
        return Promise.resolve(setI18nLanguage(lang))

    if (loadedLanguages.includes(lang))
        return Promise.resolve(setI18nLanguage(lang))

    return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}
