const eyeVersionStorage = JSON.parse(localStorage.getItem('eyeVersionStorage'))
export default () => ({
    windowWidth: null,
    windowHeight: null,
    wrapperPadding: true,
    online: true,
    showFooter: true,
    showHeader: true,
    cacheUID: localStorage.getItem('cacheUID') ? localStorage.getItem('cacheUID') : null,
    dbList: ['config', 'sort', 'task', 'order', 'groups', 'table'],
    storageList: ['user', 'task_create_form_draft'],
    serverType: process.env.NODE_ENV,
    showSidebarMenu: false,
    eyeVersion: eyeVersionStorage ? eyeVersionStorage : false,
    swUpdate: false,
    swRegistration: null,
    pushAuth: null,
    visibilityState: true,
    pwaCounter: {},
    deferredPrompt: null,
    isMobile: true,
    appType: localStorage.getItem('app_type') ? JSON.parse(localStorage.getItem('app_type')) : null,
    connectedModules: [],
    connectedRooms: []
})