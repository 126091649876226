import user from './modules/user'
import navigation from './modules/navigation'
import table from './modules/table'
import filter from './modules/filter'
import config from './modules/config'

export default {
    user,
    navigation,
    // table,
    filter,
    config
}