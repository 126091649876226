import axios from '@/config/axios'
import socket from '@/config/socket'
import { saveUsers, getUsers } from '@/utils/userUtils'
import eventBus from '@/utils/eventBus'

const deleteDb = (name) => {
    return new Promise((resolve, reject) => {
        const req = indexedDB.deleteDatabase(name)
        req.onerror = () => {
            reject(false)
        }

        req.onsuccess = () => {
            resolve(true)
        }

        req.onblocked = () => {
            resolve(true)
            console.log("Couldn't delete database due to the operation being blocked")
        }
    })
}

export default {
    skipPassword({commit}) {
        return new Promise((resolve, reject) => {
            axios.post('/users/skip_set_new_password/')
                .then(({data}) => {
                    commit('DISABLE_PASS_GENERATE')
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    setPassword({commit}, form) {
        return new Promise((resolve, reject) => {
            axios.post('/users/set_new_password/', form)
                .then(({data}) => {
                    commit('DISABLE_PASS_GENERATE')
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    getProfileMenu({commit, state}) {
        return new Promise((resolve, reject) => {
            if(state.profileMenu?.length) {
                resolve(state.profileMenu)
            } else {
                axios.get('/app_info/private_office/', {
                    params: {
                        ver: 'mobile'
                    }
                })
                    .then(({data}) => {
                        commit('SET_PROFILE_MENU', data)
                        resolve(data)
                    })
                    .catch((error) => { reject(error) })
            }
        })
    },
    async localUserLogout({commit, rootState}) {
        try {
            socket.disconnect()
        
            if(rootState.storageList?.length) {
                rootState.storageList.forEach(item => {
                    localStorage.removeItem(item)
                })
            }

            if(rootState.dbList?.length) {
                for(const key in rootState.dbList) {
                    await deleteDb(rootState.dbList[key])
                }
            }

            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            commit('SET_USER', null)
        } catch(e) {
            console.log(e, 'localUserLogout')
        }
    },
    async localUserLogout2({commit, rootState}) {
        try {
            socket.disconnect()
        
            if(rootState.storageList?.length) {
                rootState.storageList.forEach(item => {
                    localStorage.removeItem(item)
                })
            }

            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            commit('SET_USER', null)
        } catch(e) {
            console.log(e, 'localUserLogout')
        }
    },
    logout({ dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/users/logout/')
                .then(({data}) => {
                    dispatch('localUserLogout2')
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    login({ commit }, form) {
        return new Promise((resolve, reject) => {
            axios.post('/users/login/', form)
                .then(({data}) => {
                    if(data) {
                        localStorage.setItem('access_token', data.jwt_access)
                        localStorage.setItem('refresh_token', data.jwt_refresh)
                        saveUsers(data)
                        localStorage.setItem('user', JSON.stringify(data))
                        commit('SET_USER', data)
                        // socket.connect()
                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    getUserInfo({ commit, dispatch, state, rootState }, reload = false) {
        return new Promise((resolve, reject) => {
            if(rootState.appType) { 
                const user = JSON.parse(localStorage.getItem('user'))
                if(user) {
                    commit('SET_USER', user)
                    socket.connect()
                    resolve(user)
                } else {
                    if(state.user && !reload) {
                        resolve(state.user)
                    } else {
                        axios.get('/users/info/')
                            .then(({data}) => {
                                if(data?.status !== 401) {
                                    localStorage.setItem('user', JSON.stringify(data.user))
                                    commit('SET_USER', data.user)
                                    socket.connect()
                                    eventBus.$emit('user_logged')
                                } else {
                                    dispatch('localUserLogout')
                                }
                                resolve(data)
                            })
                            .catch((error) => { reject(error) })
                    }
                }
            } else {
                if(state.user && !reload) {
                    resolve(state.user)
                } else {
                    axios.get('/users/info/')
                        .then(({data}) => {
                            if(data?.status !== 401) {
                                localStorage.setItem('user', JSON.stringify(data.user))
                                commit('SET_USER', data.user)
                                socket.connect()
                                eventBus.$emit('user_logged')
                            } else {
                                dispatch('localUserLogout')
                            }
                            resolve(data)
                        })
                        .catch((error) => { reject(error) })
                }
            }
        })
    },
    init({ commit }) {
        return new Promise((resolve, reject) => {
            getUsers()
                .then(data => {
                    if(data?.value) {
                        commit('SET_USER_AUTH', data.value)
                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    getAuthConfig({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/app_info/entry/')
                .then(({data}) => {
                    commit('SET_AUTH_CONFIG', data)
                    resolve(data)
                })
                .catch((error) => { 
                    commit('SET_AUTH_CONFIG', {
                        default: true
                    })
                    resolve({
                        default: true
                    }) })
        })
    }
}
