import Vue from 'vue'
import chatRoutes from '@apps/vue2ChatComponent/config/router.js'
import ordersRoutes from '@apps/Orders/config/router.js'
import investProjectRoutes from '@apps/InvestProject/config/router.js'
import { checkNewPageWidget } from '@/utils/routerUtils'
export default {
    SET_ROUTE_INFO(state, data) {
        Vue.set(state.routeInfo, data.name, data)
    },
    SET_ROUTER_INIT(state, value) {
        let routerList = []
        for (const name in value) {
            const route = {
                ...value[name],
                component: checkNewPageWidget(value[name]),
                name,
                path: name,
                meta: {
                    ...value[name]
                }
            }

            if(route.name === 'chat') {
                Vue.set(route, 'badge', true)
                route.children = chatRoutes
                route.redirect = { name: 'chat-contact' }
            }

            routerList.push(route)
        }
        if(routerList?.length) {
            routerList = routerList.concat(ordersRoutes)
            routerList = routerList.sort(function (a, b) {
                return a.mobileOrder - b.mobileOrder
            })

            state.routerApp = [...routerList].filter(f => !f.hideMobile && !f.isHidden).concat(investProjectRoutes)
            state.routerList = [...routerList].filter(f => !f.hideMobile && !f.isHidden)
        }
    },
    CHANGE_ROUTE_SHOW(state, { value, route }) {
        return new Promise((resolve, reject) => {
            const index = state.routerList.findIndex(f => f.name === route.name)
            if(index !== -1)
                Vue.set(state.routerList[index], 'isShowMobile', value)

            resolve(true)
        })
    },
    CHANGE_ROUTE_FOOTER_SHOW(state, { value, route }) {
        return new Promise((resolve, reject) => {
            const index = state.routerList.findIndex(f => f.name === route.name)
            if(index !== -1)
                Vue.set(state.routerList[index], 'isFooter', value)

            resolve(true)
        })
    },
    CHANGE_ROUTER_LIST(state, value) {
        state.routerList = value
    },
    ADD_ROUTE(state, value) {
        state.addRoute.push(value)
    },
    SET_MENU_COUNTER(state, { value, name }) {
        Vue.set(state.counterLink, name, value)
    },
    INCREMENT_MENU_COUNTER(state, name) {
        if (typeof state.counterLink?.[name] !== "undefined") {
            state.counterLink[name]++
        }
    },
    CLEAR_MENU_COUNTER(state, name) {
        if (typeof state.counterLink?.[name] !== "undefined") {
            state.counterLink[name] = 0
        }
    },
    SET_MENU(state, value) {
        /*state.menu = value.map(item => {

            let route = {
                ...item,
                icon: replaceIcon(item)
            }

            if (item.children?.length) {
                route['children'] = item.children.map(c => {
                    let child = {
                        ...c,
                        icon: replaceIcon(c)
                    }

                    if (c.children?.type === 'import') {
                        if (c.children.config === 'task') {
                            Vue.set(child, 'children', taskRouters({meta: c.meta, state: this.state}).filter(f => !f.meta.hideSidebar))
                            child.redirect = { name: 'task-list-page' }
                            child.meta.sidebarCollapse = true
                        }
                    }

                    if (child.meta?.badgeCounter)
                        Vue.set(state.counterLink, child.name, 0)

                    return child
                })

            }

            return route
        })*/
    },
    PAGE_ROUTE_GENERATE(state, list) {
        /*let routersList = list
        
        let routers = []
        for (let p in list) {
            let children = []
            for (let c in list[p].children) {
                let child = list[p].children[c]

                if(child.children?.type === 'import') {
                    if(child.children.config === 'task') {
                        Vue.set(child, 'children', taskRoutes)
                        child.beforeEnter = (to, from, next) => {
                            if(to.name === 'tasks')
                                return next({ name: 'task-list-page' })
                            else
                                return next()
                        }
                    }
                }

                if(child.meta.pageWidget === 'BusinessProcesses') {
                    const cRoutes = await import('@apps/BusinessProcesses/config/router.js')
                    //console.log(cRoutes, 'cRoutes')
                }

                children.push({
                    ...child,
                    component: checkPageWidget(child)
                })
            }
            routers.push({
                ...list[p],
                children,
                component: checkCascadeWidget()
            })
        }

        state.appRoute = routers

        routersList = list.map(item => {
            let route = {
                ...item
            }

            if (item.meta?.badgeCounter)
                Vue.set(state.counterLink, route.name, 0)

            if(item.name === 'chat') {
                route.redirect = { name: 'chat-contact' }
                Vue.set(route, 'children', chatRoutes)
            }

            if (item.children?.length) {
                //route['component'] = checkCascadeWidget()
                route['children'] = item.children.map(c => {
                    let child = {
                        ...c,
                        component: checkPageWidget(c)
                    }

                    // if (c.name === 'goods') {
                    //     Vue.set(child, 'children', productsRoutes)
                    //     child.redirect = { name: 'products-list' }
                    // }

                    if (c.children?.type === 'import') {
                        if (c.children.config === 'task') {
                            Vue.set(child, 'children', taskRouters({meta: c.meta, state: this.state}))
                            child.redirect = { name: 'task-list-page' }
                            child.beforeEnter = (to, from, next) => {
                                if (to.name === 'tasks')
                                    return next({ name: 'task-list-page' })
                                else
                                    return next()
                            }
                            child.meta.sidebarCollapse = true
                        }
                        // COMMIT AUTHOR: RUSLAN
                        // if (c.children.config === 'businessProcesses') {
                        //     Vue.set(child, 'children', businessRoutes)
                        //     child.redirect = { name: 'business_processes_main' }
                        //     child.beforeEnter = (to, from, next) => {
                        //         if (to.name === 'business_processes')
                        //             return next({ name: 'business_processes_main' })
                        //         else
                        //             return next()
                        //     }
                        // }

                    }

                    return child
                })
            } else {
                route['component'] = checkPageWidget(item)
            }

            return route
        })

        routersList = routersList.concat(notificationsRoutes)
        routersList = routersList.concat(ordersRoutes)

        state.appRoute = routersList*/
    },
    FORM_ROUTE_GENERATE(state, list) {
        /*const cRoute = []

        list.forEach(p => {
            if (p?.children?.length) {
                p.children.forEach(c => {
                    if (c?.meta?.pageConfig?.formInfo?.length) {
                        c.meta.pageConfig.formInfo.forEach(f => {
                            cRoute.push({
                                name: f.name,
                                path: `${f.name}/:action/:id`,
                                component: checkFormWidget(f),
                                beforeEnter: (to, from, next) => {
                                    const { params, meta: { parent } } = to

                                    if (params.action === 'create' || params.action === 'update') {
                                        if (params.action === 'create') {
                                            if (uuidValidate(params.id))
                                                return next()
                                            else
                                                return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                        } else {
                                            if (uuidValidate(params.id)) {
                                                return next()
                                            } else {
                                                return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                            }
                                        }
                                    } else
                                        return next({ name: parent.name, params: { lang: Trans.currentLanguage } })
                                },
                                meta: {
                                    ...f,
                                    parent: c
                                }
                            })
                        })
                    }
                })
            }
        })*/
    },
    TOGGLE_SETTING(store, { value, type }) {
        store[type] = value
    },
    TOGGLE_ACTIVE_MENU(state, value) {
        state.activeMenu = value
    },
    PUSH_HEADER_BTN_ROUTER(state, value) {
        state.pushRoutes.push(value)
    }
}
