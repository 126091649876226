export function dataToArray(vars) {
    if (Array.isArray(vars)) {
        return vars;
    }
    return [vars];
}
var transitionEndObject = {
    transition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd otransitionend'
};
export var transitionStr = Object.keys(transitionEndObject).filter(function (key) {
    if (typeof document === 'undefined') {
        return false;
    }
    var html = document.getElementsByTagName('html')[0];
    return key in (html ? html.style : {});
})[0];
export var transitionEnd = transitionEndObject[transitionStr];

export function addEventListener(target, eventType, callback, options) {
    if (target.addEventListener) {
        target.addEventListener(eventType, callback, options);
    } else if (target.attachEvent) {
        target.attachEvent('on' + eventType, callback);
    }
}

export function removeEventListener(target, eventType, callback, options) {
    if (target.removeEventListener) {
        target.removeEventListener(eventType, callback, options);
    } else if (target.attachEvent) {
        target.detachEvent('on' + eventType, callback);
    }
}

export function transformArguments(arg, cb) {
    var result = void 0;
    if (typeof arg === 'function') {
        result = arg(cb);
    } else {
        result = arg;
    }
    if (Array.isArray(result)) {
        if (result.length === 2) {
            return result;
        }
        return [result[0], result[1]];
    }
    return [result];
}

export var isNumeric = function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value); // eslint-disable-line
};

export var windowIsUndefined = !(typeof window !== 'undefined' && window.document && window.document.createElement);

export var getTouchParentScroll = function getTouchParentScroll(root, currentTarget, differX, differY) {
    if (!currentTarget || currentTarget === document || currentTarget instanceof Document) {
        return false;
    }
    // root 为 drawer-content 设定了 overflow, 判断为 root 的 parent 时结束滚动；
    if (currentTarget === root.parentNode) {
        return true;
    }

    var isY = Math.max(Math.abs(differX), Math.abs(differY)) === Math.abs(differY);
    var isX = Math.max(Math.abs(differX), Math.abs(differY)) === Math.abs(differX);

    var scrollY = currentTarget.scrollHeight - currentTarget.clientHeight;
    var scrollX = currentTarget.scrollWidth - currentTarget.clientWidth;

    var style = document.defaultView.getComputedStyle(currentTarget);
    var overflowY = style.overflowY === 'auto' || style.overflowY === 'scroll';
    var overflowX = style.overflowX === 'auto' || style.overflowX === 'scroll';

    var y = scrollY && overflowY;
    var x = scrollX && overflowX;

    if (isY && (!y || y && (currentTarget.scrollTop >= scrollY && differY < 0 || currentTarget.scrollTop <= 0 && differY > 0)) || isX && (!x || x && (currentTarget.scrollLeft >= scrollX && scrollX < 0 || currentTarget.scrollLeft <= 0 && scrollX > 0))) {
        return getTouchParentScroll(root, currentTarget.parentNode, differX, differY);
    }
    return false;
};