import axios from '@/config/axios'
import { setData, getById, deleteDb, updateById } from '@/utils/cacheDb'

export default {
    loginConfigInit({ dispatch, commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/app_info/global/', {
                params: {
                    ver: 'mobile'
                }
            }).
                then(({ data }) => {
                    getById({ id: 'global', databaseName: 'config' })
                        .then(dbData => {
                            if (dbData?.value) {
                                updateById({
                                    id: 'global',
                                    value: data,
                                    databaseName: 'config'
                                })
                                    .then(() => {
                                        dispatch('config/init', { data })
                                        resolve(data)
                                    })
                                    .catch(e => {
                                        reject(e)
                                    })
                            } else {
                                setData({
                                    data: {
                                        id: 'global',
                                        value: data
                                    },
                                    databaseName: 'config'
                                })
                                    .then(() => {
                                        dispatch('config/init', { data })
                                        resolve(data)
                                    })
                                    .catch(e => {
                                        reject(e)
                                    })
                            }
                        })
                        .catch(e => {
                            console.log(e)
                        })
                })
                .catch(e => {
                    console.log(e, 'config init')
                    reject(e)
                })
        })
    },
    /*loginAppInit({ dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/app_info/routes/', {
                params: {
                    ver: 'mobile',
                }
            }).
                then(({ data }) => {
                    getById({ id: 'routes', databaseName: 'config' })
                        .then(dbData => {
                            if (dbData?.value?.length) {
                                updateById({
                                    id: 'routes',
                                    value: data,
                                    databaseName: 'config'
                                })
                                    .then(() => {
                                        setTimeout(() => {
                                            dispatch('navigation/init', { data })
                                            resolve(data)
                                        }, 600)
                                    })
                                    .catch(e => {
                                        reject(e)
                                    })
                            } else {
                                setData({
                                    data: {
                                        id: 'routes',
                                        value: data
                                    },
                                    databaseName: 'config'
                                })
                                    .then(() => {
                                        setTimeout(() => {
                                            dispatch('navigation/init', { data })
                                            resolve(data)
                                        }, 600)
                                    })
                                    .catch(e => {
                                        reject(e)
                                    })
                            }
                        })
                        .catch(e => {
                            console.log(e)
                        })
                })
                .catch(e => {
                    console.log(e, 'app init')
                    reject(e)
                })
        })
    },*/
    getCacheUID({ commit, state }) {
        return new Promise((resolve, reject) => {
            if(!state.appType) {
                axios.get('/app_info/check_front_cache/')
                    .then(({ data }) => {
                        getById({ id: 'isMobile', databaseName: 'app_type' })
                            .then(async dbData => {
                                let update = false
                                if (dbData) {
                                    if(dbData.value !== state.isMobile) {
                                        update = true
                                        updateById({
                                            id: 'isMobile',
                                            value: state.isMobile,
                                            databaseName: 'app_type'
                                        })
                                    }
                                } else {
                                    setData({
                                        data: {
                                            id: 'isMobile',
                                            value: state.isMobile
                                        },
                                        databaseName: 'app_type'
                                    })
                                }

                                if(state.cacheUID || update) {
                                    if(state.cacheUID !== data.uid || update) {
                                        await deleteDb({databaseName: 'config'})
                                        await deleteDb({databaseName: 'task'})
                                        console.log(`%c UPDATE CACHE 🔄`, 'color: #fa8c16')
                                    } else {
                                        console.log(`%c LOAD CACHE 🚀`, 'color: #04d182')
                                    }
                                }
        
                                localStorage.setItem('cacheUID', data.uid)
                                commit('SET_CACHE_UID', data.uid)
                                resolve(data)
                            })
                            .catch(e => {
                                console.log(e, 'check cache init')
                                reject(e)
                            })
                    })
                    .catch(e => {
                        console.log(e, 'check cache init')
                        reject(e)
                    })
            } else {
                resolve(true)
            }
        })
    },
    configInit({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            const getConfig = () => {
                getById({ id: 'global', databaseName: 'config' })
                    .then(dbData => {
                        if (dbData?.value) {
                            dispatch('config/init', { data: dbData.value })
                            resolve(dbData.value)
                        } else {
                            axios.get('/app_info/global/', {
                                params: {
                                    ver: 'mobile',
                                }
                            }).
                                then(({ data }) => {
                                    setData({
                                        data: {
                                            id: 'global',
                                            value: data
                                        },
                                        databaseName: 'config'
                                    })
                                    dispatch('config/init', { data })
                                    resolve(data)
                                })
                                .catch(e => {
                                    console.log(e, 'config init')
                                    reject(e)
                                })
                        }

                    })
                    .catch(e => {
                        console.log(e)
                    })
            }

            if(state.appType) {
                const config = JSON.parse(localStorage.getItem('config'))
                if(config) {
                    dispatch('config/init', { data: config })
                    resolve(config)
                } else {
                    getConfig()
                }
            } else {
                getConfig()
            }
        })
    },
    /*appInit({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            if(!state.appType) {
                getById({ id: 'routes', databaseName: 'config' })
                    .then(dbData => {
                        if (dbData?.value?.length) {
                            dispatch('navigation/init', { data: dbData.value })
                            resolve(dbData.value)
                        } else {
                            axios.get('/app_info/routes/', {
                                params: {
                                    ver: 'mobile',
                                }
                            }).
                                then(({ data }) => {
                                    setData({
                                        data: {
                                            id: 'routes',
                                            value: data
                                        },
                                        databaseName: 'config'
                                    })
                                        .then(() => {
                                            setTimeout(() => {
                                                dispatch('navigation/init', { data })
                                                resolve(data)
                                            }, 600)
                                        })
                                        .catch(e => {
                                            reject(e)
                                        })
                                })
                                .catch(e => {
                                    console.log(e, 'app init')
                                    reject(e)
                                })
                        }

                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else {
                resolve(true)
            }
        })
    }*/
}