<template>
    <component :is="initWidget"/>
</template>

<script>
export default {
    props: {
        folder: {
            type: String,
            required: true
        }
    },
    computed: {
        initWidget() {
            return () => import(`@apps/${this.folder}/init`)
        }
    }
}
</script>