export default () => ({
    menu: [],
    saveNav: [],
    advanceMenu: false,
    historyNav: false,
    activeMenu: null,
    appRoute: [],
    pushRoutes: [],
    historyNavList: [],
    counterLink: {},
    routerApp: [],
    routerList: [],
    routerMobile: [],
    routeInfo: {}
})